import styles from "./index.module.css";
import ChatInput from "../../../components/ChatInput";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../../providers/AppProvider";
import useChatBot from "../../../hooks/useChatBot";
import avatar from "../../../assets/images/avatar.jpg";

const PatientDashboardView = () => {
  const navigate = useNavigate();
  const { patient, eventId } = useApp();
  const { setMessage } = useChatBot();
  return (
    <div
      className={`chatBox content-wrapper`}
      style={{
        padding: "1rem",
        minHeight: "unset",
        width: "auto",
        left: 0,
        paddingBottom: "60px",
      }}
    >
      <div className="modal center-modal fade" id="modal-admit">
        <div className="modal-dialog">
          <div className="modal-content modal-video-content">
            <div className="modal-body">
              <div className="text-center">
                <h5>Someone wants to join this call</h5>
              </div>
              <div className="content-top-agile pt-10">
                <img
                  src={avatar}
                  alt="User"
                  style={{ borderRadius: "50%", width: "50px", height: "50px" }}
                />
              </div>
              <div className="text-center">
                <h5 className="mb-5">Maical Doe</h5>
              </div>
            </div>
            <div className="modal-footer text-end">
              <a
                href="#"
                className="btn btn-danger btn-xs"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Deny Entry
              </a>
              <a
                href="patient-videocall.html"
                className="btn btn-success btn-xs"
              >
                Admin
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h4 style={{ marginBottom: "0" }}>Hi, {patient?.name || "Patient"}</h4>
        <p style={{ marginBottom: "1.5rem" }}>How can I assist you Today ?</p>
      </div>
      <div
        className=" justify-content-between align-items-center overflow-hidden"
        style={{ margin: "auto", width: "100%" }}
      >
        <div>
          <div className={styles.row}>
            <div>
              <h6>Preferred actions</h6>
            </div>
            <div>
              <div className={styles.pullRight}>
                <span className="badge badge-pill badge-info">Health</span>
              </div>
            </div>
          </div>
          <div>
            <div className="row">
              <div className="col-12">
                <table width="100%" className="menu-icon-btn">
                  <tbody>
                    <tr>
                      <td width="50%">
                        <div className="d-grid gap-2">
                          <button
                            className="btn btn-primary dash-icon-menu btn-block p-15 rounded-4"
                            type="button"
                          >
                            <div>
                              <span className="material-symbols-outlined">
                                calendar_month
                              </span>
                            </div>
                            <div>Appointment</div>
                          </button>
                        </div>
                      </td>
                      <td width="50%">
                        <div
                          className="d-grid gap-2"
                          onClick={() => navigate("/chat")}
                        >
                          <button className="btn btn-primary btn-outline  rounded-4 dash-icon-menu bg-white p-15">
                            <div>
                              <span className="material-symbols-outlined">
                                stethoscope_arrow
                              </span>
                            </div>
                            <div>Check-In Tele-Med</div>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td width="50%">
                        <div className="d-grid gap-2">
                          <button
                            className="btn btn-primary btn-outline rounded-4 dash-icon-menu bg-white p-15"
                            type="button"
                          >
                            <div>
                              <span className="material-symbols-outlined">
                                receipt_long
                              </span>
                            </div>
                            <div>Med-Record</div>
                          </button>
                        </div>
                      </td>
                      <td width="50%">
                        <div className="d-grid gap-2">
                          <button
                            className="btn btn-primary btn-outline  rounded-4 dash-icon-menu bg-white p-15"
                            type="button"
                          >
                            <div>
                              <span className="material-symbols-outlined">
                                warning
                              </span>
                            </div>
                            <div>Alert</div>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="d-grid gap-2 p-3">
              <button
                type="button"
                className="btn btn-info btn-xs d-flex align-items-center justify-content-center"
                style={{ padding: "0.375rem 0.75rem", fontSize: "0.75rem" }} // Adjust padding and font size
                data-bs-toggle="modal"
                data-bs-target="#modal-admit"
              >
                <div>
                  <span className="material-symbols-outlined">groups</span>
                </div>
                <div>
                  <span>1 Person waiting</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div>
          <div className={styles.chatInput}>
            <ChatInput
              placeholder="Tell something..."
              handleSend={setMessage}
              eventId={eventId}
            />
          </div>
        </div>
        <div className="p-1 fs-6">
          &copy; {new Date().getFullYear()} <a href="#">Infinity Clinic</a>. All
          Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default PatientDashboardView;
