export const getFinalIds = (
  existingEmployeeId: string | null,
  existingCustomerId: string | null,
  paramsEmployeeId: string | null,
  paramsCustomerId: string | null
) => {
  switch (true) {
    case !!existingEmployeeId && !!paramsCustomerId:
      return { employee_id: null, customer_id: paramsCustomerId };
    case !!existingCustomerId && !!paramsEmployeeId:
      return { employee_id: paramsEmployeeId, customer_id: null };
    case !existingCustomerId && !existingEmployeeId:
      return { employee_id: paramsEmployeeId, customer_id: paramsCustomerId };
    default:
      return {
        employee_id: existingEmployeeId,
        customer_id: existingCustomerId,
      };
  }
};
