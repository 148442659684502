// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Meeting_root__K-WM3 {
  position: relative;
  height: 100%; 
}
.Meeting_fullscreenRoot__eVtmA{
  height: 100%; 
}

.Meeting_fullscreenButton__lnqcE {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10000; /* Ensure it's on top */
  color: white;
  border-radius: 12px;
  padding: 4px;
}
.Meeting_fullsizebutton__S-k24{
  position: absolute;
  top: 10px;
  right: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10000; /* Ensure it's on top */
  color: white;
  border-radius: 12px;
  padding: 4px;
}
.Meeting_fullscreenButton__lnqcE {
  background: 'none';
}

.Meeting_fullscreenButton__lnqcE span {
  font-size: 12px;
  color: white;
}

.Meeting_fullscreen__pUbX8 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
`, "",{"version":3,"sources":["webpack://./src/components/Meeting/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,cAAc,EAAE,uBAAuB;EACvC,YAAY;EACZ,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,cAAc,EAAE,uBAAuB;EACvC,YAAY;EACZ,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,aAAa;AACf","sourcesContent":[".root {\n  position: relative;\n  height: 100%; \n}\n.fullscreenRoot{\n  height: 100%; \n}\n\n.fullscreenButton {\n  position: absolute;\n  top: 5px;\n  right: 5px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  z-index: 10000; /* Ensure it's on top */\n  color: white;\n  border-radius: 12px;\n  padding: 4px;\n}\n.fullsizebutton{\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  height: 40px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  z-index: 10000; /* Ensure it's on top */\n  color: white;\n  border-radius: 12px;\n  padding: 4px;\n}\n.fullscreenButton {\n  background: 'none';\n}\n\n.fullscreenButton span {\n  font-size: 12px;\n  color: white;\n}\n\n.fullscreen {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 9999;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Meeting_root__K-WM3`,
	"fullscreenRoot": `Meeting_fullscreenRoot__eVtmA`,
	"fullscreenButton": `Meeting_fullscreenButton__lnqcE`,
	"fullsizebutton": `Meeting_fullsizebutton__S-k24`,
	"fullscreen": `Meeting_fullscreen__pUbX8`
};
export default ___CSS_LOADER_EXPORT___;
