import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import Loader from "../components/Spinner/Loader";

type AppState = {
  token: string;
  jitsi_token: string;
  employeeId?: number;
  customerId?: number;
  eventId?: number;
  employee?: Doctor;
  patient?: Patient;
  sessionId?: string;
  setEmployeeId: (id?: number) => void;
  setCustomerId: (id?: number) => void;
  setEventId: (id: number) => void;
  setEmployee: (doctor?: Doctor) => void;
  setPatient: (patient?: Patient) => void;
  setSessionId: (id?: string) => void;
  setTokens: (tokens: { token: string; jitsi_token: string }) => void;
  resetAll: () => void;
};
const AppContext = React.createContext<AppState>({
  token: "",
  jitsi_token: "",
  setEmployeeId: (id?: number) => {},
  setCustomerId: (id?: number) => {},
  setEventId: (id: number) => {},
  setEmployee: (doctor?: Doctor) => {},
  setPatient: (patient?: Patient) => {},
  setSessionId: (id?: string) => {},
  setTokens: (tokens: { token: string; jitsi_token: string }) => {},
  resetAll: () => {},
});

function AppProvider(props: PropsWithChildren) {
  const [loading, setLoading] = useState(true);
  const [employeeId, _setEmployeeId] = useState<number>();
  const [customerId, _setCustomerId] = useState<number>();
  const [eventId, _setEventId] = useState<number>();
  const [patient, _setPatient] = useState<Patient>();
  const [employee, _setEmployee] = useState<Doctor>();
  const [sessionId, _setSessionId] = useState<string>();
  const [tokens, _setTokens] = useState<{ token: string; jitsi_token: string }>(
    {
      token: "",
      jitsi_token: "",
    }
  );

  useEffect(() => {
    const employeeId = localStorage.getItem("employee_id");
    _setEmployeeId(employeeId ? parseInt(employeeId) : undefined);
    const eventId = localStorage.getItem("event_id");
    _setEventId(eventId ? parseInt(eventId) : undefined);
    const CustomerId = localStorage.getItem("customer_id");
    _setCustomerId(CustomerId ? parseInt(CustomerId) : undefined);
    const tokens = localStorage.getItem("tokens");
    _setTokens(
      tokens
        ? JSON.parse(tokens)
        : {
            token: "",
            jitsi_token: "",
          }
    );
    setTimeout(() => {
      setLoading(false);
      console.log("Now false");
    }, 2000);
  }, []);

  const setEmployee = (doctor?: Doctor) => {
    _setEmployee(doctor);
  };

  const setPatient = (patient?: Patient) => {
    _setPatient(patient);
  };

  const setEmployeeId = (id?: number) => {
    localStorage.setItem("employee_id", id ? `${id}` : "");
    _setEmployeeId(id);
  };

  const setCustomerId = (id?: number) => {
    localStorage.setItem("customer_id", id ? `${id}` : "");
    _setCustomerId(id);
  };

  const setTokens = (tokens: { token: string; jitsi_token: string }) => {
    localStorage.setItem("tokens", JSON.stringify(tokens));
    _setTokens(tokens);
  };

  const setEventId = (id: number) => {
    localStorage.setItem("event_id", id.toString());
    _setEventId(id);
  };

  const setSessionId = (id?: string) => {
    _setSessionId(id);
  };

  const resetAll = () => {
    localStorage.clear();
    _setCustomerId(undefined);
    _setEmployeeId(undefined);
    _setEventId(undefined);
    _setTokens({ token: "", jitsi_token: "" });
    _setEmployee(undefined);
    _setPatient(undefined);
    _setSessionId(undefined);
  };

  return (
    <AppContext.Provider
      value={{
        patient,
        employee,
        employeeId,
        eventId,
        customerId,
        sessionId,
        ...tokens,
        setEmployeeId,
        setCustomerId,
        setTokens,
        setEventId,
        resetAll,
        setPatient,
        setEmployee,
        setSessionId,
      }}
    >
      {loading ? <Loader /> : props.children}
    </AppContext.Provider>
  );
}

export const useApp = () => useContext(AppContext);

export default AppProvider;
