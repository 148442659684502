// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-apps_root__gSuR7 {
    /* width: 275px; */
    background-color: white;
    -webkit-user-select: none;
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-radius: 0px;
    user-select: none;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
}

.my-apps_contentWrapper__o-vrt {
    min-height: 100%;
    background-color: #ffffff;
    transition: all 0.3s ease-in-out !important;
    overflow: hidden;
    border-radius: 1.25rem;
    margin-top: 0;
}

.my-apps_content__xkyLw {
    min-height: 250px;
    margin-right: auto;
    margin-left: auto;
    top: 0;
    left: 0;
    position: relative;
}

.my-apps_row__9xy6n {
    display: flex;
    flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/views/patient/my-apps/index.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uBAAuB;IACvB,yBAAyB;IACzB,0CAA0C;IAC1C,2CAA2C;IAC3C,kBAAkB;IAElB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,2CAA2C;IAC3C,gBAAgB;IAChB,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,MAAM;IACN,OAAO;IACP,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":[".root {\n    /* width: 275px; */\n    background-color: white;\n    -webkit-user-select: none;\n    border-bottom-left-radius: 10px !important;\n    border-bottom-right-radius: 10px !important;\n    border-radius: 0px;\n    -ms-user-select: none;\n    user-select: none;\n    margin-bottom: 0px;\n    display: flex;\n    flex-direction: column;\n}\n\n.contentWrapper {\n    min-height: 100%;\n    background-color: #ffffff;\n    transition: all 0.3s ease-in-out !important;\n    overflow: hidden;\n    border-radius: 1.25rem;\n    margin-top: 0;\n}\n\n.content {\n    min-height: 250px;\n    margin-right: auto;\n    margin-left: auto;\n    top: 0;\n    left: 0;\n    position: relative;\n}\n\n.row {\n    display: flex;\n    flex-wrap: wrap;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `my-apps_root__gSuR7`,
	"contentWrapper": `my-apps_contentWrapper__o-vrt`,
	"content": `my-apps_content__xkyLw`,
	"row": `my-apps_row__9xy6n`
};
export default ___CSS_LOADER_EXPORT___;
