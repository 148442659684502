import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import LeftBar from "./LeftBar";
import Header from "./Header";
import Footer from "./Footer";
import { useApp } from "../providers/AppProvider";
import { useApi } from "../hooks/useApi";

export default function Dashboard() {
  const {
    employeeId,
    customerId,
    setEmployee,
    setPatient,
    employee,
    patient,
    setSessionId,
  } = useApp();
  const api = useApi();
  const [isLeftBarCollapsed, setIsLeftBarCollapsed] = useState(false);

  useEffect(() => {
    const setIds = async () => {
      if (employeeId && !employee) {
        const { data } = await api.get(`/employees/${employeeId}`);
        setEmployee(data);
      }
      if (customerId && !patient) {
        const { data } = await api.get(`/partners/${customerId}`);
        setPatient(data);
      }
    };
    const newSessionId = Math.random().toString(36).substr(2, 9);
    setSessionId(newSessionId);
    setIds();
  }, []);

  const toggleLeftBarHandler = () => {
    setIsLeftBarCollapsed(!isLeftBarCollapsed);
  };

  return (
    <div
      className={`${
        !isLeftBarCollapsed ? "sidebar-collapse" : ""
      } hold-transition dark-skin sidebar-mini theme-primary fixed`}
      // style={{height: "100vh"}}
    >
      <Header toggleLeftBarHandler={toggleLeftBarHandler} />
      <LeftBar />
      <Footer />
      <Outlet />
    </div>
  );
}
