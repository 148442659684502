import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import IconButton from "../IconButton";
import useChat from "../../hooks/useChat";
import { useLocation, useNavigate } from "react-router-dom";

interface ChatInputProps {
  placeholder?: string;
  handleSend?: (message: string, response?: number) => void;
  eventId?: number;
}

function ChatInput({ placeholder, handleSend, eventId }: ChatInputProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const recievedMessage = (location.state as { message: string })?.message;
  const { sendMessage } = useChat();
  const [message, setMessage] = useState(recievedMessage || "");

  const handleSendMessage = async (e?: any) => {
    if (e) e.preventDefault();
    if (message?.length === 0) return;

    if (handleSend) {
      if (location.pathname === "/dashboard") {
        navigate("/chat", { state: { message: message } });
        return;
      }
      sendMessage(message);
      handleSend(message, eventId);
      setMessage("");
      if (recievedMessage) window.history.replaceState({}, document.title);
    }
  };
  useEffect(() => {
    if (message) {
      handleSendMessage();
    }
  }, []);
  return (
    <div className={styles.root}>
      <form onSubmit={handleSendMessage}>
        <input
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          className="form-control b-0"
          style={{
            padding: "10px 10.5px",
            fontFamily: "IBM Plex Sans, sans-serif",
            boxShadow: "none",
          }}
          placeholder={placeholder ? placeholder : "Say something..."}
          type="text"
        />
      </form>

      <div className={`d-flex justify-content-between ${styles.hoverclass}`}>
        <IconButton
          className={`material-symbols-outlined ${styles.textbutton}`}
        >
          link
        </IconButton>
        <IconButton
          className={`material-symbols-outlined ${styles.textbutton}`}
        >
          mic
        </IconButton>
      </div>
      <IconButton
        className={`${styles.sendButtonhover} material-symbols-outlined`}
        onClick={handleSendMessage}
        disabled={message?.length == 0}
      >
        send
      </IconButton>
    </div>
  );
}

export default ChatInput;
