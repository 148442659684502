// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/loader.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner_spinner__0D1uq {
  width: 48px;
  height: 48px;
  border: 5px solid rgba(128, 128, 128, 0.634);
  border-bottom-color: var(--primary);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: Spinner_rotation__ReWEU 1s linear infinite;
}

@keyframes Spinner_rotation__ReWEU {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.Spinner_loader__tutBW {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  overflow: visible;
  background: #fff url(${___CSS_LOADER_URL_REPLACEMENT_0___}) no-repeat center center;
 }
`, "",{"version":3,"sources":["webpack://./src/components/Spinner/index.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,4CAA4C;EAC5C,mCAAmC;EACnC,kBAAkB;EAClB,qBAAqB;EACrB,sBAAsB;EACtB,qDAAsC;AACxC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,eAAe;EACf,WAAW;EACX,aAAa;EACb,eAAe;EACf,iBAAiB;EACjB,gFAAkE;CACnE","sourcesContent":[".spinner {\n  width: 48px;\n  height: 48px;\n  border: 5px solid rgba(128, 128, 128, 0.634);\n  border-bottom-color: var(--primary);\n  border-radius: 50%;\n  display: inline-block;\n  box-sizing: border-box;\n  animation: rotation 1s linear infinite;\n}\n\n@keyframes rotation {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.loader {\n  position: fixed;\n  width: 100%;\n  height: 100vh;\n  z-index: 999999;\n  overflow: visible;\n  background: #fff url(\"/public/loader.gif\") no-repeat center center;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `Spinner_spinner__0D1uq`,
	"rotation": `Spinner_rotation__ReWEU`,
	"loader": `Spinner_loader__tutBW`
};
export default ___CSS_LOADER_EXPORT___;
