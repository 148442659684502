import lostImg from "../../assets/images/something-lost.png";
function NotFound() {
  return (
    <>
      <div
        style={{
          margin: "auto",
          padding: "15px",
        }}
      >
        <div
          style={{
            textAlign: "center",
            marginTop: "4rem",
          }}
        >
          <img
            src={lostImg}
            alt="Wait"
            style={{
              maxWidth: "460px",
            }}
          />
          <h1
            style={{
              fontSize: "34px",
              fontWeight: "400",
              fontFamily: "Helvetica Neue ,Helvetica,Arial,sans-serif",
            }}
          >
            Oops, looks like the page is lost.
          </h1>
          <p
            style={{
              fontSize: "22px",
              textAlign: "center",
              fontWeight: "300",
              lineHeight: "28px",
              marginTop: "20px",
              marginBottom: "10px",
              fontFamily: "Helvetica Neue ,Helvetica,Arial,sans-serif",
            }}
          >
            This is not a fault, just an accident that was not intentional.
          </p>
        </div>
      </div>
    </>
  );
}

export default NotFound;
