import "./App.css";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import Dashboard from "./views/dashboard/DashboardView";
import NotFound from "./views/not-found/NotFound";
import Authorization from "./views/authorization/authorization";
import PatientVideoCallView from "./views/patient/video-call/PatientVideoCallView";
import PatientChatView from "./views/patient/chat/PatientChatView";
import PatientDashboardView from "./views/patient/dashboard/PatientDashboard";
import AppProvider, { useApp } from "./providers/AppProvider";
import MyApps from "./views/patient/my-apps/MyApps";

function ProtectedRoute(props: { role: "patient" | "doctor" | "all" }) {
  const { role } = props;
  const { employeeId, customerId } = useApp();

  if (typeof employeeId !== "number" && typeof customerId !== "number") {
    return <Navigate to="/" />;
  }

  if (role === "doctor" && typeof employeeId !== "number") {
    return <Navigate to="/" />;
  } else if (role === "patient" && typeof customerId !== "number") {
    return <Navigate to="/" />;
  }
  return <Outlet />;
}
function App() {
  return (
    <div className="App">
      <AppProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Authorization />} />
            <Route path="/authenticate" element={<Authorization />} />
            <Route element={<ProtectedRoute role="all" />}>
              <Route element={<MainLayout />}>
                <Route element={<ProtectedRoute role="doctor" />}>
                  <Route path="dashboard/:room" element={<Dashboard />} />
                </Route>
                <Route element={<ProtectedRoute role="patient" />}>
                  <Route path="/chat" element={<PatientChatView />} />
                  <Route path="/dashboard" element={<PatientDashboardView />} />
                  <Route
                    path="/patient-videocall/:room"
                    element={<PatientVideoCallView />}
                  />
                </Route>
                <Route path="/my-apps" element={<MyApps />} />
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </AppProvider>
    </div>
  );
}

export default App;
