import React from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.css";

interface Props {
  name: string;
  icon: string;
  color: string;
}

const AppCard: React.FC<Props> = ({ name, icon, color }) => {
  return (
    <div className="col-lg-2 col-md-3 col-sm-3 col-3" draggable="false">
      <Link
        to="#"
        className={`${styles.adminMenuBox} p-2 waves-effect waves-light ${color}`}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title=""
        data-bs-original-title={name}
      >
        <div className={`${styles.box}`}>
          <div className={`${styles.boxBody}`}>
            <div>
              <span className="material-symbols-outlined">{icon}</span>
            </div>
            <h5>{name}</h5>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default AppCard;
