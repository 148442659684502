// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatLoader_typing-indicator__slMBq {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .ChatLoader_dot__EFlt\\+ {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: #fff; /* Changed to white */
    border-radius: 50%;
    display: inline-block;
    animation: ChatLoader_typing__CfFu\\+ 1.2s infinite ease-in-out;
  }
  
  @keyframes ChatLoader_typing__CfFu\\+ {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  
  .ChatLoader_dot__EFlt\\+:nth-child(1) {
    animation-delay: -0.32s;
  }
  
  .ChatLoader_dot__EFlt\\+:nth-child(2) {
    animation-delay: -0.16s;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/ChatLoader/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;EACd;;EAEA;IACE,UAAU;IACV,WAAW;IACX,aAAa;IACb,sBAAsB,EAAE,qBAAqB;IAC7C,kBAAkB;IAClB,qBAAqB;IACrB,8DAA2C;EAC7C;;EAEA;IACE;MACE,mBAAmB;IACrB;IACA;MACE,mBAAmB;IACrB;EACF;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;EACzB","sourcesContent":[".typing-indicator {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    height: 100%;\n  }\n  \n  .dot {\n    width: 8px;\n    height: 8px;\n    margin: 0 2px;\n    background-color: #fff; /* Changed to white */\n    border-radius: 50%;\n    display: inline-block;\n    animation: typing 1.2s infinite ease-in-out;\n  }\n  \n  @keyframes typing {\n    0%, 80%, 100% {\n      transform: scale(0);\n    }\n    40% {\n      transform: scale(1);\n    }\n  }\n  \n  .dot:nth-child(1) {\n    animation-delay: -0.32s;\n  }\n  \n  .dot:nth-child(2) {\n    animation-delay: -0.16s;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typing-indicator": `ChatLoader_typing-indicator__slMBq`,
	"dot": `ChatLoader_dot__EFlt+`,
	"typing": `ChatLoader_typing__CfFu+`
};
export default ___CSS_LOADER_EXPORT___;
