// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tablink_nav-link__nX54H {
    cursor: pointer;
    transition: border-bottom-width 0.3s, border-bottom-color 0.3s;
  }
  
  .Tablink_nav-link__nX54H.Tablink_text-muted__YCv-x {
    border-bottom: 1px solid transparent;
  }
  
  .Tablink_nav-link__nX54H.Tablink_text-primary__QET0K {
    border-bottom: 2px solid #6f42c1;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Tablink/index.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,8DAA8D;EAChE;;EAEA;IACE,oCAAoC;EACtC;;EAEA;IACE,gCAAgC;EAClC","sourcesContent":[".nav-link {\n    cursor: pointer;\n    transition: border-bottom-width 0.3s, border-bottom-color 0.3s;\n  }\n  \n  .nav-link.text-muted {\n    border-bottom: 1px solid transparent;\n  }\n  \n  .nav-link.text-primary {\n    border-bottom: 2px solid #6f42c1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"nav-link": `Tablink_nav-link__nX54H`,
	"text-muted": `Tablink_text-muted__YCv-x`,
	"text-primary": `Tablink_text-primary__QET0K`
};
export default ___CSS_LOADER_EXPORT___;
