// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patient-meeting_root__JQyrH {
  position: relative;
}
.patient-meeting_root__JQyrH .patient-meeting_chat__W5C4p {
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
}
.patient-meeting_videoCallOption__TmNTJ {
  position:fixed;
  bottom:0px;
  width:100%;
  background-color:#00AFF0;
  z-index:9999999;
}

.patient-meeting_videoCallOption__TmNTJ span{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  height: 40px;
  width: 40px;
  background-color: #ffff;
  color: #6750A4;
  border-radius: 50%;
}
.patient-meeting_videoCallOption__TmNTJ span:hover{
  color: #313a3d;
  background-color: rgb(233 233 233);

}
#patient-meeting_largeVideoWrapper__\\+3GO7 {
  inset: 0px !important;
  height: 100vh !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/patient-meeting/index.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,YAAY;AACd;AACA;EACE,cAAc;EACd,UAAU;EACV,UAAU;EACV,wBAAwB;EACxB,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,kCAAkC;;AAEpC;AACA;EACE,qBAAqB;EACrB,wBAAwB;AAC1B","sourcesContent":[".root {\n  position: relative;\n}\n.root .chat {\n  position: absolute;\n  width: 100%;\n  top: 0;\n  height: 100%;\n}\n.videoCallOption {\n  position:fixed;\n  bottom:0px;\n  width:100%;\n  background-color:#00AFF0;\n  z-index:9999999;\n}\n\n.videoCallOption span{\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 24px;\n  height: 40px;\n  width: 40px;\n  background-color: #ffff;\n  color: #6750A4;\n  border-radius: 50%;\n}\n.videoCallOption span:hover{\n  color: #313a3d;\n  background-color: rgb(233 233 233);\n\n}\n#largeVideoWrapper {\n  inset: 0px !important;\n  height: 100vh !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `patient-meeting_root__JQyrH`,
	"chat": `patient-meeting_chat__W5C4p`,
	"videoCallOption": `patient-meeting_videoCallOption__TmNTJ`,
	"largeVideoWrapper": `patient-meeting_largeVideoWrapper__+3GO7`
};
export default ___CSS_LOADER_EXPORT___;
