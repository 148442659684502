import AppCard from "../../../components/AppCard";
import styles from "./index.module.css";
import React from "react";

const apps = [
  {
    color: "text-warning",
    name: "Digital Info",
    icon: "add_chart",
  },
  {
    color: "text-primary",
    name: "Dr.Notes",
    icon: "prescriptions",
  },
  {
    color: "text-secondary",
    name: "Personal Notes",
    icon: "description",
  },
  {
    color: "text-success",
    name: "Market Place",
    icon: "local_convenience_store",
  },
  {
    color: "text-info",
    name: "Share Token",
    icon: "token",
  },
  {
    color: "text-danger",
    name: "Genomics",
    icon: "genetics",
  },
  {
    color: "text-dark",
    name: "Prescription",
    icon: "medication",
  },
  {
    color: "text-secondary",
    name: "Treatment Plan 1",
    icon: "overview",
  },
  {
    color: "text-success",
    name: "Treatment Plan 2",
    icon: "export_notes",
  },
  {
    color: "text-info",
    name: "Video Call",
    icon: "video_call",
  },
  {
    color: "text-danger",
    name: "Plan Visit",
    icon: "short_stay",
  },
  {
    color: "text-dark",
    name: "IoT",
    icon: "home_iot_device",
  },
  {
    color: "text-success",
    name: "Execrise Plan",
    icon: "component_exchange",
  },
  {
    color: "text-info",
    name: "OTC & Supplements",
    icon: "pill",
  },
  {
    color: "text-danger",
    name: "Diet Plan",
    icon: "grocery",
  },
  {
    color: "text-dark",
    name: "Brain Plan",
    icon: "network_intelligence_history",
  },
  {
    color: "text-success",
    name: "ToB",
    icon: "aod_watch",
  },
  {
    color: "text-primary",
    name: "PLM",
    icon: "chronic",
  },
  {
    color: "text-success",
    name: "Device",
    icon: "wrist",
  },
  {
    color: "text-primary",
    name: "Pacs",
    icon: "rib_cage",
  },
  {
    color: "text-danger",
    name: "Test",
    icon: "stethoscope",
  },
  {
    color: "text-warning",
    name: "Alerts",
    icon: "admin_meds",
  },
];

const MyApps = () => {

  return (
    <div className={`box ${styles.root} chatBox`}>
      <div className={`${styles.contentWrapper} content-wrapper`}>
        <div className="container-full">
          <section className={`${styles.content}`}>
            <div className="row" id="sortable">
              {apps.map((app, index) => (
                <AppCard key={index} {...app} />
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default MyApps;
