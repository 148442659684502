// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AppCard_adminMenuBox__ok8Fo {
    display: block;
    max-width: 180px;
    margin: auto;
    text-align: center;
    text-decoration: none;
}

@media only screen and (max-width: 1024px) {
    .AppCard_adminMenuBox__ok8Fo h5 {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .AppCard_adminMenuBox__ok8Fo .AppCard_boxBody__tsUTr {
        padding: 0px !important;
        padding-top: 10px !important;
        padding-bottom: 5px !important;
    }
}

.AppCard_box__QTYF8 {
    margin: 5px;
    margin-bottom: 1.5rem;
    position: relative;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 0px;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.05);
}

.AppCard_boxBody__tsUTr {
    padding: 1.5rem 0.5rem;
    flex: 1 1 auto;
    border-radius: 10px;
}

.AppCard_boxBody__tsUTr>*:last-child {
    margin-bottom: 0.5rem;
}

.AppCard_boxBody__tsUTr>h5 {
    font-size: 1.042857rem;
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/components/AppCard/index.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,uBAAuB;QACvB,4BAA4B;QAC5B,8BAA8B;IAClC;AACJ;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,kBAAkB;IAClB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,6CAA6C;AACjD;;AAEA;IACI,sBAAsB;IACtB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;AACpB","sourcesContent":[".adminMenuBox {\n    display: block;\n    max-width: 180px;\n    margin: auto;\n    text-align: center;\n    text-decoration: none;\n}\n\n@media only screen and (max-width: 1024px) {\n    .adminMenuBox h5 {\n        display: none;\n    }\n}\n\n@media only screen and (max-width: 1024px) {\n    .adminMenuBox .boxBody {\n        padding: 0px !important;\n        padding-top: 10px !important;\n        padding-bottom: 5px !important;\n    }\n}\n\n.box {\n    margin: 5px;\n    margin-bottom: 1.5rem;\n    position: relative;\n    background-color: #ffffff;\n    border-radius: 10px;\n    padding: 0px;\n    transition: 0.5s;\n    display: flex;\n    flex-direction: column;\n    box-shadow: 0 3px 5px 1px rgba(0, 0, 0, 0.05);\n}\n\n.boxBody {\n    padding: 1.5rem 0.5rem;\n    flex: 1 1 auto;\n    border-radius: 10px;\n}\n\n.boxBody>*:last-child {\n    margin-bottom: 0.5rem;\n}\n\n.boxBody>h5 {\n    font-size: 1.042857rem;\n    font-weight: 400;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"adminMenuBox": `AppCard_adminMenuBox__ok8Fo`,
	"boxBody": `AppCard_boxBody__tsUTr`,
	"box": `AppCard_box__QTYF8`
};
export default ___CSS_LOADER_EXPORT___;
