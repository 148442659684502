import { useState } from "react";
import { useApi } from "./useApi";
import { useApp } from "../providers/AppProvider";

type Message = IncomingBotMessage | OutgoingChatBotMessage;

function useChatBot() {
  const api = useApi();
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const [actions, setActions] = useState<
    Array<{ type: string; options: Array<{ text: string }> }>
  >([]);
  const { sessionId } = useApp();

  /**
   * Abstract method to get message from gcp, update messages and
   * handle loading state
   * @param text
   */
  async function setResult(text: string) {
    setLoading(true);
    try {
      const payload = {
        queryInput: {
          text: text,
          sessionId,
          init: messages.length === 0,
        },
      };
      const { data } = await api.post(`/google-intent`, payload);
      setActions(data.actions || []);
      setMessages((messages) => [
        ...messages,
        {
          message: data.text,
          privateMessage: false,
          buttons: data?.buttons,
        },
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
  /**
   * Set message to state and invoke get result
   * @param message
   */
  function handleSetMessage(message: string) {
    setMessages((messages) => [
      ...messages,
      { message: [message], from: "me", privateMessage: false, nick: "User" },
    ]);
    setResult(message);
    setActions([]);
  }

  return {
    messages,
    setMessage: handleSetMessage,
    chatLoading: loading,
    actions,
  };
}

export default useChatBot;
