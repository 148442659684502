import styles from "./index.module.css";
import ChatInput from "../ChatInput";
import ChatMessage from "../ChatMessage";

import useChatBot from "../../hooks/useChatBot";
import { useApp } from "../../providers/AppProvider";
import { useEffect, useRef } from "react";

function ChatBot() {
  const { messages, setMessage, chatLoading, actions } = useChatBot();

  const { eventId } = useApp();
  const chatListRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (chatListRef.current) {
      chatListRef.current.scrollTop = chatListRef.current.scrollHeight;
    }
  }, [messages, actions, setMessage]);
  return (
    <div
      className={`box ${styles.root} chatBox content-wrapper`}
      style={{
        width: "auto",
        left: 0,
      }}
    >
      <div className={styles.chat_list} ref={chatListRef}>
        <div className={styles.row}>
          <div>
            <h6>Message</h6>
          </div>
          <div>
            <div className={styles.pullRight}>
              <span className="badge badge-pill badge-info">Archive</span>
            </div>
          </div>
        </div>
        <div className={styles.chat_end} />
        {messages &&
          messages.map((message, index) => (
            <>
              {message.message.map((botMessage) => {
                return (
                  <ChatMessage
                    key={index}
                    data={{ ...message, message: botMessage }}
                    isBot={true}
                  />
                );
              })}
            </>
          ))}
        {chatLoading && (
          <ChatMessage
            data={{ privateMessage: false, message: "" }}
            isBot={true}
            loading={chatLoading}
          />
        )}

        {actions.length != 0 && (
          <div className={styles.buttonBox}>
            {actions.map((action) => {
              if (action.type !== "chips") return null;
              return (
                <>
                  {action.options.map((option) => {
                    return (
                      <button
                        onClick={() => setMessage(option.text)}
                        className={styles.button}
                      >
                        {option.text}
                      </button>
                    );
                  })}
                </>
              );
            })}
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <div className="p-1 fs-6">
          &copy; {new Date().getFullYear()} <a href="#">Infinity Clinic</a>. All
          Rights Reserved.
        </div>
        <div className={styles.chatInput}>
          <ChatInput handleSend={setMessage} eventId={eventId} />
        </div>
      </div>
    </div>
  );
}
export default ChatBot;
