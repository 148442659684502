// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconButton_button__QQwhD {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  color: #cccccfde;
}
.IconButton_primary__Xc\\+ob {
  background-color: #00aff0;
}
.IconButton_button__QQwhD:hover {
  opacity: 0.9;
}
/* .button:disabled {
  cursor: no-drop;
} */`, "",{"version":3,"sources":["webpack://./src/components/IconButton/index.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;AACd;AACA;;GAEG","sourcesContent":[".button {\n  height: 32px;\n  width: 32px;\n  border-radius: 50%;\n  outline: none;\n  border: none;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: transparent;\n  cursor: pointer;\n  color: #cccccfde;\n}\n.primary {\n  background-color: #00aff0;\n}\n.button:hover {\n  opacity: 0.9;\n}\n/* .button:disabled {\n  cursor: no-drop;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `IconButton_button__QQwhD`,
	"primary": `IconButton_primary__Xc+ob`
};
export default ___CSS_LOADER_EXPORT___;
