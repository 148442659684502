// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MiddleSection_addNoteBtn__NA-6r {
  margin:20px 0.5rem  !important;
  padding: 0 1rem;
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/components/MiddleSection/index.module.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,eAAe;EACf,aAAa;AACf","sourcesContent":[".addNoteBtn {\n  margin:20px 0.5rem  !important;\n  padding: 0 1rem;\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addNoteBtn": `MiddleSection_addNoteBtn__NA-6r`
};
export default ___CSS_LOADER_EXPORT___;
