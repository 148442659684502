// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PatientReportBar_tabs__LsQOf {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.PatientReportBar_tabContent__dM2I1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/dahsboard/DashboardElements/PatientReportBar/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".tabs {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  margin-top: 1rem;\n}\n.tabContent {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": `PatientReportBar_tabs__LsQOf`,
	"tabContent": `PatientReportBar_tabContent__dM2I1`
};
export default ___CSS_LOADER_EXPORT___;
