import { JaaSMeeting } from "@jitsi/react-sdk";
import styles from "./index.module.css";
import useChat from "../../hooks/useChat";
import PatientChat from "./PatientChat/index";
import { useState, useRef } from "react";
import { IJitsiMeetExternalApi } from "@jitsi/react-sdk/lib/types";
import { useNavigate } from "react-router-dom";
import VideoCallChat from "../VideoCallChat";

type Props = {
  jwt: string;
  roomName: string;
};

function Meeting(props: Props) {
  const { setApi, setCurrentAppointment } = useChat();
  const [isChatDisplay, setIsChatDisplay] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [isAudioOn, setIsAudioOn] = useState(false);
  const navigate = useNavigate();
  const jitsiApiRef = useRef<IJitsiMeetExternalApi | null>(null); // Create a ref to store the Jitsi API instance

  const readyToClose = () => {
    setCurrentAppointment({});
  };

  const toggleChatModal = () => {
    setIsChatDisplay(!isChatDisplay);
  };

  const handleJitsiApi = (action: string) => {
    if (jitsiApiRef.current) {
      switch (action) {
        case "toggleVideo":
          setIsVideoOn(!isVideoOn);
          jitsiApiRef.current.executeCommand("toggleVideo");
          break;
        case "toggleAudio":
          setIsAudioOn(!isAudioOn);
          jitsiApiRef.current.executeCommand("toggleAudio");
          break;
        case "hangup":
          jitsiApiRef.current.executeCommand("hangup");
          navigate("/dashboard");
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className={styles.root}>
      <JaaSMeeting
        appId={"vpaas-magic-cookie-2245ac56e2d94efe9ab7ef727989f4b1"}
        onApiReady={(api) => {
          setApi(api);
          jitsiApiRef.current = api;
          api.addListener("readyToClose", readyToClose);
        }}
        interfaceConfigOverwrite={{
          TOOLBAR_BUTTONS: [],
        }}
        configOverwrite={{
          startWithVideoMuted: true,
          startWithAudioMuted: true,
          prejoinPageEnabled: false,
          enableRecording: true,
          pipEnabled: true,
          disableProfile: true,
          requireDisplayName: false,
        }}
        userInfo={{
          displayName: "",
          email: "",
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = "92vh";
        }}
        {...props}
      />
      {isChatDisplay && (
        <div className={styles.chat}>
          <VideoCallChat isfullscreen={true} />
        </div>
      )}

      {/* Custom Toolbar */}
      <div>
        <div className={styles.videoCallOption}>
          <div className="d-flex justify-content-around">
            <div
              className="py-3 footer-bottom-menu-call"
              onClick={() => handleJitsiApi("toggleVideo")}
            >
              <span className="material-symbols-outlined">
                {isVideoOn ? "videocam" : "videocam_off"}
              </span>
            </div>
            <div
              className="py-3 footer-bottom-menu-call"
              onClick={() => handleJitsiApi("toggleAudio")}
            >
              <span className="material-symbols-outlined">
                {isAudioOn ? "mic" : "mic_off"}
              </span>
            </div>
            <div
              className="py-3 footer-bottom-menu-call"
              onClick={() => handleJitsiApi("hangup")}
            >
              <span className="material-symbols-outlined text-danger">
                call_end
              </span>
            </div>
            <div
              className="py-3 footer-bottom-menu-call"
              onClick={toggleChatModal}
            >
              <span className="material-symbols-outlined">
                {isChatDisplay ? "chat_bubble" : "chat_bubble"}
              </span>
            </div>
            <div className="py-3 footer-bottom-menu-call">
              <span className="material-symbols-outlined">add_call</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Meeting;
